import React from "react";
import "./LoadingSpinner.css";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
        <p>Loading...</p>
      <div className="loading-spinner">

      </div>
    </div>
  );
}